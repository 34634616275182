<!--宁夏企业大屏-->
<template>
    <div class="bigScreenBox">
        <div class="enterpriseScreen">
            <header>
                <span @click="centerDialogVisible = true">能耗安全环保监测</span>
            </header>
            <div class="query-box">
                <span class="name">筛选条件</span>
                <div class="query-item">
                    <label>场站：</label>
                    <ul>
                        <li
                            :class="{active:item.active}"
                            v-for="item in stationList"
                            :key="item.name"
                            @click="handleSwitch(item)"
                        >
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
                <div class="query-item">
                    <label>生产线：</label>
                    <ul>
                        <li
                            :class="{active:item.active}"
                            v-for="item in lineList"
                            :key="item.name"
                            @click="handleSwitchLine(item)"
                        >
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <section>
                <div class="left-box badage">
                    <h2 class="title">
                        能耗监测
                    </h2>
                    <div class="base-area">
                        <ul>
                            <li>
                                <div class="base">
                                    <p class="name">
                                        当日用能:
                                    </p>
                                    <p class="color1">
                                        <strong id="curDayUse"></strong><span class="font1"> KW·h</span>
                                    </p>
                                </div>
                            </li>
                            <li class="big-w">
                                <div class="base">
                                    <p class="name">
                                        当日趋势:
                                    </p>
                                    <p class="color2">
                                        +<strong id="curDayQs"></strong><strong>%</strong>
                                    </p>
                                </div>
                                <div class="extr color2">
                                    <div>+<span id="curDayQsKm"></span></div>
                                    <span>KW·h</span>
                                </div>
                            </li>
                            <li style="margin-left: 15px;">
                                <div class="base">
                                    <p class="name">
                                        当月用能:
                                    </p>
                                    <p class="color3">
                                        <strong id="curMonthUse">{{ curMonthUse }}</strong><span style="font-family: 'Microsoft YaHei'">万KW·h</span>
                                    </p>
                                </div>
                            </li>
                            <li class="big-w">
                                <div class="base">
                                    <p class="name">
                                        当月趋势:
                                    </p>
                                    <p class="color2">
                                        <strong id="curMonthQs">{{ curMonthQs }}</strong><strong>%</strong>
                                    </p>
                                </div>
                                <div class="extr color2">
                                    <div>+<span id="curMonthQsKm"></span><i>万</i></div>
                                    <span>KW·h</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="samePerc chart-area">
                        <div class="top">
                            <label class="name">同比分析</label>
                            <div class="nav-box">
                                <el-radio-group v-model="samePercNav" style="margin-bottom: 0.3rem;">
                                    <el-radio-button label="day">
                                        当日
                                    </el-radio-button>
                                    <el-radio-button label="month">
                                        本月
                                    </el-radio-button>
                                    <el-radio-button label="year">
                                        年度
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div id="samePerc-chart" class="samePerc-chart"></div>
                    </div>
                    <div class="power chart-area">
                        <div class="top">
                            <label class="name">能耗趋势分析</label>
                            <div class="nav-box">
                                <el-radio-group v-model="energyNav" style="margin-bottom: 0.3rem;">
                                    <el-radio-button label="day">
                                        当日
                                    </el-radio-button>
                                    <el-radio-button label="month">
                                        本月
                                    </el-radio-button>
                                    <el-radio-button label="year">
                                        年度
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div id="power-chart" class="power-chart"></div>
                    </div>
                </div>
                <div class="right-box badage">
                    <div class="base-panel data-panel ">
                        <h2 class="title">
                            环保监测
                        </h2>
                        <h3 class="title2">
                            建筑工地环境监测 <span class="font2">2010-5-10</span>
                        </h3>
                        <div class="base-list">
                            <div class="item ">
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_1.png">
                                    <div class="txt">
                                        <label>风力:</label>
                                        <span id="windGrade"></span><span>级</span>
                                    </div>
                                </div>
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_2.png">
                                    <div class="txt">
                                        <label>风速:</label>
                                        <span id="windSpeed"></span><span class="font1">米/S</span>
                                    </div>
                                </div>
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_3.png">
                                    <div class="txt">
                                        <label>风向:</label>
                                        <span>{{ windDirection }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="item ">
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_4.png">
                                    <div class="txt">
                                        <label>PM浓度:</label>
                                        <span class="font1">ug/m3</span>
                                    </div>
                                </div>
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_5.png">
                                    <div class="txt">
                                        <label>PM2.5:</label>
                                        <span>00</span><span id="pm2_5"></span>
                                    </div>
                                </div>
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_6.png">
                                    <div class="txt">
                                        <label>PM10:</label>
                                        <span>00</span><span id="pm10"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="item ">
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_7.png">
                                    <div class="txt">
                                        <label>温度:</label>
                                        <span id="temperature"></span><span class="font1">℃</span>
                                    </div>
                                </div>
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_8.png">
                                    <div class="txt">
                                        <label>湿度:</label>
                                        <span>00</span><span id="humidity"></span>
                                    </div>
                                </div>
                                <div class="cell">
                                    <img class="icon" src="./images/enterpriseScreen_nx/icon_9.png">
                                    <div class="txt">
                                        <label>噪音:</label>
                                        <span>00</span><span id="noise"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="safe-monitor data-panel">
                        <h2 class="title">
                            环保监测
                        </h2>
                        <div class="content">
                            <div class="safe-warning chart-area">
                                <div class="top">
                                    <label class="name">安全生产预警指数</label>
                                </div>
                                <div class="chart-box">
                                    <div id="yibiao-chart" class="yibiao-chart">
                                    </div>
                                    <div class="legend">
                                        <div class="item">
                                            <i style="background:#FF4242 "></i><span>危险级</span>
                                        </div>
                                        <div class="item">
                                            <i style="background:#F7B500 "></i><span>预警级</span>
                                        </div>
                                        <div class="item">
                                            <i style="background:#00D415 "></i><span>安全级</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accident-montior chart-area">
                                <div class="top">
                                    <label class="name">事故隐患监管</label>
                                    <div class="nav-box">
                                        <el-radio-group v-model="accidentNav" style="margin-bottom: 0.3rem;">
                                            <el-radio-button label="day">
                                                当日
                                            </el-radio-button>
                                            <el-radio-button label="month">
                                                本月
                                            </el-radio-button>
                                            <el-radio-button label="year">
                                                年度
                                            </el-radio-button>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div id="accident-chart" class="accident-chart"></div>
                                <div class="danger-pie-area">
                                    <div id="danger-pie-chart"></div>
                                    <div class="legend">
                                        <div class="item">
                                            <i class="tag"></i><label>一般隐患A：</label><span>14</span>
                                        </div>
                                        <div class="item">
                                            <i class="tag"></i><label>一般事故隐患：</label><span>6</span>
                                        </div>
                                        <div class="item">
                                            <i class="tag"></i><label>重大隐患B：</label><span>1</span>
                                        </div>
                                        <div class="item">
                                            <i class="tag"></i><label>重大事故隐患：</label><span>2</span>
                                        </div>
                                        <div class="item">
                                            <i class="tag"></i><label>重大隐患：</label><span>0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="danger-list">
                                <div class="item">
                                    <div class="head nomal">
                                        <span class="name">金凤站</span>
                                        <span class="grade">一般隐患A</span>
                                    </div>
                                    <div class="img-list">
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list1.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list2.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list3.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list4.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list2.jpg">
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="head best">
                                        <span class="name">金凤站</span>
                                        <span class="grade">重大隐患B</span>
                                    </div>
                                    <div class="img-list">
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list1.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list2.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list3.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list4.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list2.jpg">
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="head better">
                                        <span class="name">金凤站</span>
                                        <span class="grade">一般事故隐患</span>
                                    </div>
                                    <div class="img-list">
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list1.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list2.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list3.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list4.jpg">
                                        </div>
                                        <div class="img-box">
                                            <img src="./images/enterpriseScreen_nx/list2.jpg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script scoped>
/* eslint-disable*/
import './css/enterpriseScreen_nx.styl';
import { DigitRoll } from './../../assets/js/charts/digitRoll.js';
export default {
    name: 'enterprise-screen-nx',
    data() {
        return {
            samePercNav: 'day', // 同比分析切换栏
            energyNav: 'day', // 能耗趋势分析
            accidentNav: 'day', // 事故隐患监管
            curDayUse: 0, // 当日用能
            curDayQs: 0, // 当日趋势
            curDayQsKm: 0, // 当日趋势 速度
            curMonthUse: 0, // 当月用能
            curMonthQs: 0, // 当月趋势
            curMonthQsKm: 0, // 当月趋势速度

            windGrade: 0, // 风力
            windSpeed: 0, // 风速
            windDirection: '南', // 风向
            pmPot: 0, // PM浓度
            pm2_5: 0, // PM2.5
            pm10: 0, // PM10
            temperature: 0, // 温度
            humidity: 0, // 湿度
            noise: 0, // 噪音


            stationList: [{
                name: '金凤站',
                active: true,
                lineList: [{
                    name: '1号线',
                    active: true,
                }, {
                    name: '2号线',
                    active: true,
                }, {
                    name: '3号线',
                    active: true,
                }, {
                    name: '4号线',
                    active: true,
                }, {
                    name: '5号线',
                    active: true,
                }],
            }, {
                name: '宁东站',
                active: false,

                lineList: [{
                    name: '6号线',
                    active: true,
                }, {
                    name: '7号线',
                    active: true,
                }, {
                    name: '8号线',
                    active: true,
                }],
            }, {
                name: '西夏站',
                active: false,
                lineList: [{
                    name: '9号线',
                    active: true,
                }, {
                    name: '10号线',
                    active: true,
                }],
            }, {
                name: '煜浩站',
                active: false,
                lineList: [{
                    name: '11号线',
                    active: true,
                }, {
                    name: '12号线',
                    active: true,
                }],
            }],

            lineList: [],
        };
    },
    watch: {
        samePercNav(val) {
            this.initSamePerChart();
        },
        energyNav(val) {
            this.initPowerChart();
        },
        accidentNav(val) {
            this.initAccidentChart();
            this.initDangerPieChart();
        },
    },
    created() {
        this.lineList = this.stationList[0].lineList;
    },
    mounted() {
        this.initData();
    },
    methods: {
        initData() {
            // 随机数据
            this.randomBaseData();

            this.initSamePerChart();
            this.initPowerChart();
            this.initAccidentChart();

            this.initDangerPieChart();
            this.initYibiaoChart();

        },
        // 生成随机数据
        randomBaseData() {
            this.electronicNumber('#curDayUse', 8640);
            this.electronicNumber('#curDayQs', 5.3);
            this.electronicNumber('#curDayQsKm', 435);
            this.electronicNumber('#curMonthUse', 25.9);
            this.electronicNumber('#curMonthQs', 6.3);
            this.electronicNumber('#curMonthQsKm', 1.6);

            this.electronicNumber('#windGrade', 0o3);
            this.electronicNumber('#windSpeed', 0.3);
            this.electronicNumber('#pm2_5', 2);
            this.electronicNumber('#pm10', 3);

            this.electronicNumber('#temperature', 2);
            this.electronicNumber('#humidity', 2);
            this.electronicNumber('#noise', 3);
        },
        // 格式化电子数字
        electronicNumber(obj, nub) {
            if (!nub) {
                nub = 0.0;
            }
            new DigitRoll({
                container: obj,
            }).roll(nub);
        },
        handleSwitch(item) {
            this.stationList.forEach(el => {el.active = false;});
            item.active = true;
            this.lineList = item.lineList;
            this.initData();
        },
        handleSwitchLine(item) {
            item.active = !item.active;
            this.initData();
        },
        // 初始化同比分析
        initSamePerChart() {
            const datalist = {
                day: {
                    list: [{ xValue: '1号线', yValue: 4500 }, { xValue: '2号线', yValue: 2900 }, { xValue: '3号线', yValue: 4010 }, { xValue: '4号线', yValue: 4200 }, { xValue: '5号线', yValue: 4200 },  { xValue: '6号线', yValue: 4200 },{ xValue: '7号线', yValue: 2900 }, { xValue: '8号线', yValue: 4010 }, { xValue: '9号线', yValue: 3600 }, { xValue: '10号线', yValue: 4200 }, { xValue: '11号线', yValue: 4500 }, { xValue: '12号线', yValue: 4100 }],
                    prevList: [{ xValue: '1号线', yValue: 3980 }, { xValue: '2号线', yValue: 2500 }, { xValue: '3号线', yValue: 4700 }, { xValue: '4号线', yValue: 4050 },  { xValue: '5号线', yValue: 4500 },{ xValue: '6号线', yValue: 4400 }, { xValue: '7号线', yValue: 2200 }, { xValue: '8号线', yValue: 3800 }, { xValue: '9号线', yValue: 4200 }, { xValue: '10号线', yValue: 4200 }, { xValue: '11号线', yValue: 4200 }, { xValue: '12号线', yValue: 4000 }],
                },
                month: {
                    list: [{ xValue: '1号线', yValue: 2900 }, { xValue: '2号线', yValue: 3980 }, { xValue: '3号线', yValue: 4200 }, { xValue: '4号线', yValue: 4010 }, { xValue: '5号线', yValue: 4400 },  { xValue: '6号线', yValue: 4200 },{ xValue: '7号线', yValue: 4010 }, { xValue: '8号线', yValue: 4010 }, { xValue: '9号线', yValue: 4200 }, { xValue: '10号线', yValue: 4600 }, { xValue: '11号线', yValue: 4000 }, { xValue: '12号线', yValue: 4800 }],
                    prevList: [{ xValue: '1号线', yValue: 3980 }, { xValue: '2号线', yValue: 4500 }, { xValue: '3号线', yValue: 4050 }, { xValue: '4号线', yValue: 4700 }, { xValue: '5号线', yValue: 4200 }, { xValue: '6号线', yValue: 4200 }, { xValue: '7号线', yValue: 3800 }, { xValue: '8号线', yValue: 2900 }, { xValue: '9号线', yValue: 4000 }, { xValue: '10号线', yValue: 4400 }, { xValue: '11号线', yValue: 4200 }, { xValue: '12号线', yValue: 4500 }],
                },
                year: {
                    list: [{ xValue: '1号线', yValue: 6000 }, { xValue: '2号线', yValue: 4700 }, { xValue: '3号线', yValue: 4050 }, { xValue: '4号线', yValue: 4100 }, { xValue: '5号线', yValue: 4400 },  { xValue: '6号线', yValue: 4300 },{ xValue: '7号线', yValue: 2900 }, { xValue: '8号线', yValue: 4010 }, { xValue: '9号线', yValue: 3600 }, { xValue: '10号线', yValue: 4200 }, { xValue: '11号线', yValue: 4500 }, { xValue: '12号线', yValue: 4600 }],
                    prevList: [{ xValue: '1号线', yValue: 4580 }, { xValue: '2号线', yValue: 4010 }, { xValue: '3号线', yValue: 4700 }, { xValue: '4号线', yValue: 4050 }, { xValue: '5号线', yValue: 4100 }, { xValue: '6号线', yValue: 4600 }, { xValue: '7号线', yValue: 2200 }, { xValue: '8号线', yValue: 3800 }, { xValue: '9号线', yValue: 4200 }, { xValue: '10号线', yValue: 4500 }, { xValue: '11号线', yValue: 4200 }, { xValue: '12号线', yValue: 5000 }],
                },
            };

            const lines = [];
            this.lineList.forEach(item=> {
                if(item.active){
                    lines.push(item.name);
                }
            })
            const list = datalist[this.samePercNav].list.filter(item=>lines.includes(item.xValue));
            const prevList = datalist[this.samePercNav].prevList.filter(item=>lines.includes(item.xValue));

            const myChart = this.$echarts.init(document.getElementById('samePerc-chart'));
            myChart.clear();
            myChart.resize();
            const xdata = [];
            const ydata = [
                { name: '上一年', colors: ['#76E0A8', '#13BD63'], data: [] },
                { name: '本年', colors: ['#EFAB54', '#FA8C47'], data: [] },
            ];
            list.forEach((item, index) => {
                xdata.push(item.xValue);
                ydata[0].data.push( item.yValue);
                ydata[1].data.push(prevList[index].yValue);
            });
            const newOption = {
                animationEasing: 'elasticOut',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                legend: {
                    right: 15,
                    itemWidth: 12,
                    itemHeight: 12,
                    data: ['上一年', '本年'],
                    textStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        fontSize: 10,
                    },
                },
                grid: {
                    left: 50,
                    top: 40,
                    right: 10,
                    bottom: 20,
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.1)',
                            type: 'solid',
                            width: 1,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            fontSize: 12,
                        },
                    },
                },
                yAxis: [
                    {
                        name: '单位（度）',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            fontSize: 12,
                        },
                        splitNumber: 5,
                        type: 'value',
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 1,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: 'rgba(255,255,255,0.5)',
                            },
                        },
                        splitArea: {
                            areaStyle: {
                                color: 'rgba(255,255,255,.5)',
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: [],
            };
            ydata.forEach(item => {
                newOption.series.push(
                    {
                        name: item.name,
                        type: 'bar',
                        barMaxWidth: 30,
                        silent: true,
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: item.colors[0] },
                                        { offset: 1, color: item.colors[1] },
                                    ]
                                ),
                            },
                        },
                        label: {
                            show: false,
                            position: [-10, -10],
                            distance: 2,
                            width: 30,
                            height: 12,
                            color: '#01C8FE',
                            fontWeight: 'bolder',
                            fontSize: 12,
                            align: 'center',
                            lineHeight: 16,
                            padding: [0, 10, 0, 10],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        z: 1,
                        animationEasing: 'elasticOut',
                        data: item.data,
                    },
                    {
                        type: 'pictorialBar',
                        itemStyle: {
                            normal: {
                                color: '#0e1c31',
                            },
                        },
                        tooltip: {
                            show: false,
                        },
                        symbolRepeat: 'fixed',
                        symbolMargin: 3,
                        symbol: 'rect',
                        symbolClip: true,
                        symbolSize: [80, 1.8],
                        symbolPosition: 'start',
                        symbolOffset: [0, 6],
                        z: 2,
                        animationEasing: 'elasticOut',
                        data: item.data,
                    }
                );
            });
            myChart.setOption(newOption, true );
        },
        // 能耗趋势分析
        initPowerChart() {

            const drawLineChart = window.$globalHub.$echarts.init(document.getElementById('power-chart'));

            drawLineChart.clear();
            drawLineChart.resize();
            // eslint-disable-next-line max-len
            const data = {
                xdata: [],
                ydata: [
                    {
                        name: '能耗',
                        lineColor: ['#01C8FE', '#076DFD'],
                        areaColor: ['rgba(1, 200, 254, 0.1)', 'rgba(7, 109, 253, 0.04)'],
                        data: [],
                    },
                ],
            };
            const dataList = {
                day:[2000,2600,2500,2200,1000,1860,2200,2100,2300,1500,1600,1580,2000,3500,4000,6000,5500,5800,6000,7000,6500,6000,5800,5400,5200],
                month:[2000,3500,4000,6000,5500,5800,6000,7000,6500,6000,5800,5400,5200,2000,2600,1580,2000,3500,4000,5200,2000,2600,2500,2200,1000,1860,2200,2100,2300,1500,1600],
                year:[7000,6500,3500,4000,5400,5200,6000,5500,5800,6000,6000,5800],
            };
            let list = [];
            const fmt=(num, lastStr = '',prevStr = '')=>{
                for(let i = 1;i <= num; i++){
                    const xvalue = prevStr + (i<10?'0'+i:i) + lastStr;
                    data.xdata.push(xvalue);
                }
                list = dataList[this.energyNav].slice(0,num);
            }
            if(this.energyNav === 'day'){
                const hours = new Date().getHours();
                fmt(hours,':00');
            }else if(this.energyNav === 'month'){
                const days = new Date().getDate();
                let month = new Date().getMonth()+1;
                month =  (month<10?'0'+month:month);
                fmt(days,'',month + '-');
            }else if(this.energyNav === 'year'){
                const month = new Date().getMonth()+1;
                fmt(month,'月');
            }
            data.ydata[0].data = list;
            // list.forEach(val => {
            //     data.ydata[0].data.push(val);
            // });
            // 近一周销量
            const newOption = {
                animationEasing: 'elasticOut',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        // label: {
                        //     show: true,
                        // },
                    },
                },
                legend: {
                    show: false,
                    itemWidth: 12,
                    itemHeight: 12,
                },
                grid: {
                    left: 50,
                    top: 40,
                    right: 10,
                    bottom: 40,
                },
                dataZoom:[
                    { //y轴固定,让内容滚动
                        type: 'slider',
                        xAxisIndex: [0],
                        show:true, //显示滚轴
                        height:8, //设置滚轴的高度
                        bottom:0, //设置滚轴在底部
                        start: 0,
                        end: 100,//设置X轴刻度之间的间隔(根据数据量来调整)
                        borderColor:'#0e1c31',
                        zoomLock: true, //锁定区域禁止缩放(鼠标滚动会缩放,所以禁止)
                        minValueSpan:0,//用于限制窗口大小的最小值（实际数值）。
                        maxValueSpan:7,//用于限制窗口大小的最大值（实际数值）。
                        realtime:true,//拖动时，是否实时更新系列的视图。如果设置为 false，则只在拖拽结束的时候更新
                        showDetail: false,//即拖拽时候滚动条两侧不显示信息 默认true
                        filterMode:'empty' // 溢出窗口数据隐藏
                    },
                ],
                xAxis: {
                    type: 'category',
                    data: data.xdata,
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.1)',
                            type: 'solid',
                            width: 1,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            fontSize: 12,
                        },
                    },
                },
                yAxis: [
                    {
                        name: '单位（度）',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            fontSize: 12,
                        },
                        splitNumber: 5,
                        type: 'value',
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 1,
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: 'rgba(255,255,255,0.5)',
                            },
                        },
                        splitArea: {
                            areaStyle: {
                                color: 'rgba(255,255,255,.5)',
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: [],
            };
            data.ydata.forEach(item => {
                newOption.series.push({
                    name: item.name,
                    type: 'line',
                    symbolSize: 8,
                    itemStyle: {
                        borderWidth: 1,
                        borderColor: 'rgba(1, 200, 254, 1)',
                    },
                    lineStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: item.lineColor[0], // 0% 处的颜色
                            }, {
                                offset: 1, color: item.lineColor[0], // 100% 处的颜色
                            }],
                            global: false, // 缺省为 false
                        },
                        width: 2,
                    },
                    areaStyle: {
                        normal: {
                            color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: item.areaColor[0],
                            },
                            {
                                offset: 1,
                                color: item.areaColor[1],
                            },
                            ], false),
                        },
                    },
                    data: item.data,
                },);
            });
            drawLineChart.setOption(newOption, true);
        },
        // 事故隐患监管柱状图
        initAccidentChart() {
            const drawBarChart = window.$globalHub.$echarts.init(document.getElementById('accident-chart'));
            drawBarChart.clear();
            drawBarChart.resize();
            // eslint-disable-next-line max-len
            const datalist = [{ xValue: '金凤站', yValue1: 22, yValue2: 8 }, { xValue: '煜浩站', yValue1: 18, yValue2: 13 }, { xValue: '宁东站', yValue1: 14, yValue2: 13 }, { xValue: '西夏站', yValue1: 23, yValue2: 18 }];
            const data = {
                xAxis: {
                    data: [],
                },
                legend: {
                    itemWidth: 12,
                    itemHeight: 12,
                },
                yAxis: {
                    name: '',
                    nameTextStyle: [0, 0, -7, 0],
                },
                series: [
                    {
                        name: '上报个数 ',
                        barWidth: 20,
                        color: [ '#01C8FE', '#076DFD'],
                        data: [],
                    }, {
                        name: '整改个数',
                        barWidth: 20,
                        color: ['#FCEF20', '#FEBD29' ],
                        data: [],
                    },
                ],
            };
            datalist.forEach(element => {
                data.xAxis.data.push(element.xValue);
                data.series[0].data.push(element.yValue1);
                data.series[1].data.push(element.yValue2);
            });


            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    top: 10,
                    right: 20,
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: 'rgba(255,255,255,0.6)',
                    },
                },
                grid: {
                    left: 50,
                    top: 30,
                    right: 20,
                    bottom: 20,
                },
                calculable: true,
                xAxis: [{
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [],
                series: [],
            };
            if (data) {
                const series = [];
                option.xAxis[0].data = data.xAxis.data;
                option.yAxis.push({
                    name: data.yAxis.name,
                    nameTextStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        padding: data.yAxis.nameTextStyle,
                    },
                    yAxisIndex: 1,
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.1)',
                            width: 0.5,
                            type: 'dashed',
                        },
                    },
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    axisLabel: {
                        show: true,
                        color: 'rgba(255,255,255,0.5)',
                    },
                    axisTick: {
                        show: false,
                    },
                });
                data.series.forEach(item => {
                    series.push({
                        name: item.name,
                        type: 'bar',
                        barMaxWidth: item.barWidth,
                        data: item.data,
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [{
                                        offset: 0,
                                        color: item.color[0],
                                    },
                                    {
                                        offset: 1,
                                        color: item.color[1],
                                    },
                                    ]
                                ),
                            },
                        },
                    });
                });
                option.series = series;
            }
            drawBarChart.setOption(option);

        },
        // 事故隐患监管隐患环形图
        initDangerPieChart() {
            const chart = window.$globalHub.$echarts.init(document.getElementById('danger-pie-chart'));
            chart.clear();
            chart.resize();
            // eslint-disable-next-line max-len
            const chartData = {
                data: [],
            };
            const data = {
                一般隐患A: {
                    value: 14,
                    color: ['#D5C940', '#5EC875'],
                },
                一般事故隐患: {
                    value: 0,
                    color: ['#FCEF20', '#FEBD29'],
                },
                重大隐患B: {
                    value: ' 6 ',
                    color: ['#F57D51', '#F94E4D'],
                },
                重大事故隐患: {
                    value: 0,
                    color: ['#7BE9CB', '#00B59B'],
                },
                重大隐患: {
                    value: 0,
                    color: ['#01C8FE', '#076DFD'],
                },
            };
            Object.keys(data).forEach(key => {
                chartData.data.push({
                    name: key,
                    value: data[key].value,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: data[key].color[0], // 0% 处的颜色
                            }, {
                                offset: 1, color: data[key].color[1], // 100% 处的颜色
                            }],
                            global: false, // 缺省为 false
                        },
                    },
                });
            });
            const option = {
                title: {
                    text: '隐患\n等级',
                    textStyle: {
                        fontSize: 14,
                        color: 'rgba(255,255,255,0.8)',
                    },
                    top: '36%',
                    left: '16%',
                },
                tooltip: {
                    show: true,
                    textStyle: {
                        color: '#fff',
                        fontSize: 12,
                    },
                },

                label: {
                    show: false,
                    position: 'center',
                },
                legend: {
                    show: false,
                    width: 260,
                    top: 30,
                    right: 30,
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 22,
                    // padding: [5, 30],
                    align: 'auto',
                    textStyle: {
                        color: 'rgba(255,255,255,0.6)',
                        fontSize: 12,
                    },
                    selectedMode: false,
                    emphasis: {
                        selectorLabel: false,
                    },
                    formatter: function (name) {
                        return name + ': ' + data[name].value;
                    },
                },

                series: [
                    {
                        name: '隐患等级',
                        type: 'pie',
                        center: ['20%', '45%'],
                        radius: ['40%', '48%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: chartData.data,
                    },
                ],
            };
            chart.setOption(option, true );
        },

        // 安全预警指数
        initYibiaoChart() {
            const chart = window.$globalHub.$echarts.init(document.getElementById('yibiao-chart'));
            chart.clear();
            chart.resize();
            const val = 96;
            const option = {
                series: [{
                    type: 'gauge',
                    radius: '90%',
                    center: ['50%', '55%'],
                    startAngle: 235,
                    endAngle: -55,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: [
                                [0.79, '#FF4242'],
                                [0.88, '#F7B500'],
                                [1, '#00D415'],
                            ],
                        },
                    },
                    pointer: {
                        width: 4,
                        itemStyle: {
                            color: 'auto',
                        },
                    },
                    axisTick: {
                        length: 13,
                        lineStyle: {
                            color: 'auto',
                            width: 2,
                        },
                    },
                    splitLine: {
                        length: 23,
                        distance: 6,
                        lineStyle: {
                            width: 3,
                            color: 'auto',
                        },
                    },
                    axisLabel: {
                        distance: 8,
                        color: 'auto',
                        fontSize: 12,
                    },

                    title: {
                        show: false,
                    },
                    markPoint: {
                        // 仪表盘指针圆
                        animation: false,
                        silent: true,
                        data: [{
                            x: '50%',
                            y: '55%',
                            symbol: 'circle',
                            symbolSize: 15,
                            itemStyle: {
                                color: 'auto',
                            },
                        }, {
                            x: '50%',
                            y: '55%',
                            symbol: 'circle',
                            symbolSize: 10,
                            itemStyle: {
                                color: '#0e1c31',
                            },
                        }],
                        z: 99,
                    },
                    detail: {
                        show: false,
                        // color: '#fff',
                        valueAnimation: true,
                        fontSize: 16,
                        offsetCenter: [0, '40%'],
                        formatter: function (value) {
                            return value + '%';
                        },
                    },
                    data: [{
                        value: val,
                    }],
                    z: 1,
                }, {
                    type: 'gauge',
                    radius: '95%',
                    center: ['50%', '55%'],
                    startAngle: 235,
                    endAngle: -55,
                    axisLine: {
                        lineStyle: {
                            width: 33,
                            color: [
                                [1, '#061122'],
                            ],
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    pointer: {
                        show: false,
                    },
                    detail: {
                        color: '#fff',
                        valueAnimation: true,
                        fontSize: 16,
                        offsetCenter: [0, '40%'],
                        formatter: function (value) {
                            return value + '%';
                        },
                    },
                    data: [{
                        value: val,
                    }],
                    z: 0,
                }],
            };
            chart.setOption(option, true );
        },
    },
};
</script>

<style scoped>

</style>
